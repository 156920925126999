<template>
  <div v-if="legalPerson" class="d-flex flex-row address-card-custom">
    <div class="w-100 flex-shrink-0 offcanvas-desktop">
      <div class="navi navi-bold navi-hover navi-active navi-link-rounded d-flex justify-content-between" role="tablist">
        <template v-for="(comp, idx) in componentList">
          <div v-if="comp.canShow" :key="idx" class="navi-item mb-2">
            <router-link active-class="active" class="navi-link mobile py-4" :data-tab="idx" data-toggle="tab" role="tab"
              aria-selected="false" :to="{ name: comp.routeName }">
              <span class="navi-icon">
                <i :class="comp.icon"></i>
              </span>
            </router-link>
          </div>
        </template>
      </div>
    </div>

    <div class="flex-row-auto offcanvas-mobile w-300px w-xl-350px">
      <div class="card card-custom card-stretch">
        <div class="card-body">
          <div class="d-flex align-items-center">
            <Avatar style="-webkit-box-shadow:none; box-shadow:none" :avatar-image="legalPerson.logo"
              :avatar-text="`${legalPerson.name[0]}`" frame size="90px" class="mr-3"></Avatar>
            <div>
              <a class="font-weight-bolder font-size-h5 text-dark-75" :style="{ wordBreak: 'break-word' }">{{
                legalPerson.name }}</a>
              <div>
                <country-flag class="mt-2" :country-iso="legalPerson.country_of_establishment"></country-flag>
              </div>
            </div>
          </div>

          <div class="py-9">
            <div class="d-flex align-items-center justify-content-between mb-2">
              {{ $t("Email") }}:
              <a v-if="mainEmail" class="text-muted text-hover-primary" :style="{ minWidth: '0' }">{{ mainEmail }}</a>
              <router-link v-else :to="{ name: 'detail-legal-contacts', params: { companyID: legalPerson.id } }"
                class="btn btn-xs btn-light-primary font-weight-bolder">
                <span class="svg-icon menu-icon">
                  <inline-svg src="/media/svg/icons/Code/Plus.svg" />
                </span>
                {{ $t("Add email") }}
              </router-link>
            </div>
            <div class="d-flex align-items-center justify-content-between mb-2">
              {{ $t("Phone") }}:
              <span v-if="mainPhoneNumber" class="text-muted" :style="{ minWidth: '0' }">{{ mainPhoneNumber }}</span>
              <router-link v-else :to="{ name: 'detail-legal-contacts', params: { companyID: legalPerson.id } }"
                class="btn btn-xs btn-light-primary font-weight-bolder">
                <span class="svg-icon menu-icon">
                  <inline-svg src="/media/svg/icons/Code/Plus.svg" />
                </span>
                {{ $t("Add phone") }}
              </router-link>
            </div>
            <div v-if="secondmentRelations" class="d-flex align-items-center justify-content-between mb-2">
              {{ $t("Client on") }}: <span class="text-muted">{{ secondmentRelations }} {{
                $t("assignments") }}</span>
            </div>
            <div v-if="contractorRelations" class="d-flex align-items-center justify-content-between mb-2">
              {{ $t("Contractor on") }}: <span class="text-muted">{{ contractorRelations }} {{
                $t("assignments") }}</span>
            </div>
            <div v-if="partnerRelations" class="d-flex align-items-center justify-content-between mb-2">
              {{ $t("Supplier on") }}: <span class="text-muted">{{ partnerRelations }} {{
                $t("assignments") }}</span>
            </div>
            <div v-if="!isMyCompany" class="d-flex flex-wrap gap-1 mt-4">
              <div v-b-tooltip.hover :title="secondmentRelations != 0 ? $t('Action disabled, used on Assignment') : ''">
                <client-button :loading="isSettingAsClient" :active="legalPerson.is_client_on_secondments"
                  :disabled="secondmentRelations != 0"
                  @click="setAsClientFunction(!legalPerson.is_client_on_secondments)"></client-button>
              </div>
              <div v-b-tooltip.hover :title="contractorRelations != 0 ? $t('Action disabled, used on Assignment') : ''">
                <contractor-button :loading="isSettingAsContractor" :active="legalPerson.is_contractor_on_secondments"
                  :disabled="contractorRelations != 0"
                  @click="setAsContractorFunction(!legalPerson.is_contractor_on_secondments)"></contractor-button>
              </div>
              <div v-b-tooltip.hover :title="partnerRelations != 0 ? $t('Action disabled, used on Assignment') : ''">
                <supplier-button :loading="isSettingAsPartner" :active="legalPerson.is_partner_on_secondments"
                  :disabled="partnerRelations != 0"
                  @click="setAsPartnerFunction(!legalPerson.is_partner_on_secondments)"></supplier-button>
              </div>
            </div>
          </div>

          <div class="navi navi-bold navi-hover navi-active navi-link-rounded" role="tablist">
            <template v-for="(comp, idx) in componentList">
              <div v-if="comp.show" :key="idx" class="navi-item mb-2">
                <router-link active-class="active" class="navi-link py-4" :data-tab="idx" data-toggle="tab" role="tab"
                  aria-selected="false" :to="{ name: comp.routeName }">
                  <span class="navi-icon">
                    <i :class="comp.icon"></i>
                  </span>
                  <span class="navi-text font-size-lg">{{ comp.name }}</span>
                </router-link>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <div class="flex-row-fluid ml-lg-8">
      <router-view :key="$route.fullPath" v-bind="currentDetailComponentProps"
        v-on="currentDetailComponentEvents"></router-view>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CompanyService from "@/core/services/company/company.service";
import { backendErrorSwal } from "@/core/helpers/swal";
import ClientButton from "@/view/components/buttons/ClientButton.vue";
import ContractorButton from "@/view/components/buttons/ContractorButton.vue";
import SupplierButton from "@/view/components/buttons/SupplierButton.vue";
import icons from "@/core/config/icons";
import CountryFlag from "@/view/components/CountryFlag.vue";
import { dangerToast } from "@/core/helpers";
import { legalPersonSetAsButtonSwal } from "@/core/helpers/swal";
import { mapState } from "vuex";
import LegalPersonContactsCard from "@/view/pages/legal-person/components/LegalPersonContactsCard.vue";

export default {
  components: {
    ClientButton,
    ContractorButton,
    SupplierButton,
    CountryFlag
  },

  data() {
    return {
      icons,
      tabIndex: 0,
      legalPerson: null,
      isSettingAsContractor: false,
      isSettingAsClient: false,
      isSettingAsPartner: false,
      lastId: null
    };
  },

  computed: {
    ...mapState("user", ["user"]),
    currentDetailComponentProps() {
      return this.currentDetail?.props;
    },

    currentDetailComponentEvents() {
      return this.currentDetail?.events;
    },

    currentDetail() {
      return this.componentList.find((item) => item.routeName == this.$route.name);
    },

    componentList() {
      return [
        {
          routeName: "detail-legal-information",
          name: this.$t("Company Information"),
          icon: "fas fa-industry",
          props: {
            legalPerson: this.legalPerson,
          },
          events: {
            updated: this.loadLegalPerson,
          },
          show: true
        },
        {
          routeName: "detail-legal-contacts",
          name: this.$t("Contacts"),
          icon: "flaticon2-phone",
          component: LegalPersonContactsCard,
          props: {
            legalPerson: this.legalPerson,
          },
          events: {
            updated: this.loadLegalPerson,
          },
          show: true
        },
        {
          routeName: "detail-legal-contact-person",
          name: this.$t("Contact Person"),
          icon: "fas fa-user",
          props: {
            legalPerson: this.legalPerson,
          },
          events: {
            updated: this.loadLegalPerson,
          },
          show: true
        },
        {
          routeName: "detail-legal-address",
          name: this.$t("Address"),
          icon: "fas fa-road",
          props: {
            legalPerson: this.legalPerson,
          },
          events: {
            updated: this.loadLegalPerson,
          },
          show: true
        },
        {
          routeName: "detail-legal-documents",
          name: this.$t("Documents"),
          icon: icons.document,
          props: {
            legalPerson: this.legalPerson,
          },
          events: {
            updated: this.loadLegalPerson,
          },
          show: this.isMyCompany
        },
        {
          routeName: "detail-legal-vat",
          name: this.$t("VAT"),
          icon: icons.document,
          props: {
            legalPerson: this.legalPerson,
          },
          events: {
            updated: this.loadLegalPerson,
          },
          show: true
        },
        {
          routeName: "detail-legal-fiscal",
          name: this.$t("Fiscal ID"),
          icon: icons.document,
          props: {
            legalPerson: this.legalPerson,
          },
          events: {
            updated: this.loadLegalPerson,
          },
          show: true
        },
        {
          routeName: "detail-legal-social-security",
          name: this.$t("Social Security"),
          icon: icons.document,
          props: {
            legalPerson: this.legalPerson,
          },
          events: {
            updated: this.loadLegalPerson,
          },
          show: true
        },
        {
          routeName: "detail-legal-groups",
          name: this.$t("Company Groups"),
          icon: "fas fa-users",
          props: {
            legalPerson: this.legalPerson,
            companyGroups: this.legalPerson.company_group,
          },
          events: {
            updated: this.loadLegalPerson,
          },
          show: true
        },
        // {
        //   routeName: "detail-legal-employees",
        //   name: this.$t("Employees"),
        //   icon: icons.employee,
        //   props: {
        //     legalPerson: this.legalPerson,
        //   },
        //   events: {
        //     updated: this.loadLegalPerson,
        //   },
        //   show: this.isMyCompany
        // },
        {
          routeName: "detail-legal-assignments",
          name: this.$t("Assignments"),
          icon: "flaticon-placeholder-3",
          canShow: this.clientRelations || this.contractorRelations || this.partnerRelations,
          props: {
            legalPerson: this.legalPerson,
          },
          events: {},
          show: true
        },
        {
          routeName: "detail-legal-settings",
          name: this.$t("Service Settings"),
          icon: "flaticon-settings",
          props: {
            legalPerson: this.legalPerson,
          },
          show: this.isMyCompany
        }
      ];
    },

    currentDetailComponent() {
      return this.componentList[this.tabIndex].component;
    },

    mainPhoneNumber() {
      const mainTelContact = this.legalPerson.telcontactscompany_set.find((el) => !!el.main);
      if (!mainTelContact) {
        if (this.legalPerson?.telcontactscompany_set?.length)
          return this.legalPerson.telcontactscompany_set[0].number;

        return "";
      }
      return mainTelContact.number;
    },

    mainEmail() {
      const mainEmail = this.legalPerson.emailcompany_set.find((el) => !!el.main);
      if (!mainEmail) {
        if (this.legalPerson.emailcompany_set.length) return this.legalPerson.emailcompany_set[0].email;

        return "";
      }
      return mainEmail.email;
    },

    companyID() {
      if (this.$route.params.companyID) {
        return parseInt(this.$route.params.companyID);
      }
      return null;
    },

    secondmentRelations() {
      return this.legalPerson.secondment_client_relations.length;
    },

    contractorRelations() {
      return this.legalPerson.secondment_contractor_relations.length;
    },

    partnerRelations() {
      return this.legalPerson.secondment_partner_relations.length;
    },

    isMyCompany() {
      return this.companyID == this.user.selected_company
    }
  },

  watch: {
    '$route.params.companyID': {
      handler: function (value) {
        if (value && this.lastId != value) {
          this.lastId = value;
          this.legalPerson = null;
          this.loadLegalPerson();
        }
      },
      deep: true,
      immediate: true
    }
  },

  async mounted() {
    await this.$store.dispatch("quickActions/setComponent", { component: "BackToWizardBtn" });
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("Legal person"), route: { name: "manage-legal-person" } },
      { title: this.$t("Details") },
    ]);
  },

  methods: {
    /**
     * Set the company as a secondment client.
     * @param company
     */
    async setAsClientFunction(enable) {
      const res = await legalPersonSetAsButtonSwal(this.$t("Client"), enable);
      if (res.isConfirmed) {
        this.isSettingAsClient = true;
        await CompanyService.update({ secondment_client: enable, name: this.legalPerson.name }, this.legalPerson.id).then(() => {
          this.legalPerson.is_client_on_secondments = enable;
        }).catch(err => {
          this.legalPerson.is_client_on_secondments = !enable;
          dangerToast(err?.response?.data?.error);
        }).finally(() => {
          this.isSettingAsClient = false;
        });
      }
    },

    /**
     * Set the company as a secondment contractor.
     * @param company
     */
    async setAsContractorFunction(enable) {
      const res = await legalPersonSetAsButtonSwal(this.$t("Contractor"), enable);
      if (res.isConfirmed) {
        this.isSettingAsContractor = true;
        await CompanyService.update({ secondment_contractor: enable, name: this.legalPerson.name }, this.legalPerson.id).then(() => {
          this.legalPerson.is_contractor_on_secondments = enable;
        }).catch(err => {
          console.log(err);
          this.legalPerson.is_contractor_on_secondments = !enable;
          dangerToast(err?.response?.data?.error);
        }).finally(() => {
          this.isSettingAsContractor = false;
        });
      }
    },

    /**
     * Set the company as a secondment partner.
     * @param company
     */
    async setAsPartnerFunction(enable) {
      const res = await legalPersonSetAsButtonSwal(this.$t("Supplier"), enable);
      if (res.isConfirmed) {
        this.isSettingAsPartner = true;
        await CompanyService.update({ secondment_partner: enable, name: this.legalPerson.name }, this.legalPerson.id).then(() => {
          this.legalPerson.is_partner_on_secondments = enable;
        }).catch(err => {
          this.legalPerson.is_partner_on_secondments = !enable;
          dangerToast(err?.response?.data?.error);
        }).finally(() => {
          this.isSettingAsPartner = false;
        });
      }
    },

    /**
     * Set current active on click.
     * This is a metronic's method.
     * @param event
     */
    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("navi-link")) {
        target = event.target.closest(".navi-link");
      }

      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".navi-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      // set current active tab
      target.classList.add("active");
    },

    async loadLegalPerson() {
      await CompanyService.getOne(this.companyID).then(res => {
        return CompanyService.updateCompanyAddress(res.data);
      }).then(res => {
        this.legalPerson = res;
      }).catch(err => {
        // Nel caso in cui non sia autorizzato l'errore è comunque 404
        if (err.response.status === 404) {
          this.$router.push({ name: '404' }); //unauthorized
          return
        }
        backendErrorSwal(err?.response?.data?.detail, err);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.address-card-custom {
  flex-wrap: wrap;
}

@media screen and (min-width: 991.98px) {
  .address-card-custom {
    flex-wrap: nowrap;
  }

  .offcanvas-desktop {
    display: none;
  }
}

.gap-1 {
  gap: 1rem;
}
</style>
