var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.legalPerson ? _c('div', {
    staticClass: "d-flex flex-row address-card-custom"
  }, [_c('div', {
    staticClass: "w-100 flex-shrink-0 offcanvas-desktop"
  }, [_c('div', {
    staticClass: "navi navi-bold navi-hover navi-active navi-link-rounded d-flex justify-content-between",
    attrs: {
      "role": "tablist"
    }
  }, [_vm._l(_vm.componentList, function (comp, idx) {
    return [comp.canShow ? _c('div', {
      key: idx,
      staticClass: "navi-item mb-2"
    }, [_c('router-link', {
      staticClass: "navi-link mobile py-4",
      attrs: {
        "active-class": "active",
        "data-tab": idx,
        "data-toggle": "tab",
        "role": "tab",
        "aria-selected": "false",
        "to": {
          name: comp.routeName
        }
      }
    }, [_c('span', {
      staticClass: "navi-icon"
    }, [_c('i', {
      class: comp.icon
    })])])], 1) : _vm._e()];
  })], 2)]), _c('div', {
    staticClass: "flex-row-auto offcanvas-mobile w-300px w-xl-350px"
  }, [_c('div', {
    staticClass: "card card-custom card-stretch"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('Avatar', {
    staticClass: "mr-3",
    staticStyle: {
      "-webkit-box-shadow": "none",
      "box-shadow": "none"
    },
    attrs: {
      "avatar-image": _vm.legalPerson.logo,
      "avatar-text": "".concat(_vm.legalPerson.name[0]),
      "frame": "",
      "size": "90px"
    }
  }), _c('div', [_c('a', {
    staticClass: "font-weight-bolder font-size-h5 text-dark-75",
    style: {
      wordBreak: 'break-word'
    }
  }, [_vm._v(_vm._s(_vm.legalPerson.name))]), _c('div', [_c('country-flag', {
    staticClass: "mt-2",
    attrs: {
      "country-iso": _vm.legalPerson.country_of_establishment
    }
  })], 1)])], 1), _c('div', {
    staticClass: "py-9"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between mb-2"
  }, [_vm._v(" " + _vm._s(_vm.$t("Email")) + ": "), _vm.mainEmail ? _c('a', {
    staticClass: "text-muted text-hover-primary",
    style: {
      minWidth: '0'
    }
  }, [_vm._v(_vm._s(_vm.mainEmail))]) : _c('router-link', {
    staticClass: "btn btn-xs btn-light-primary font-weight-bolder",
    attrs: {
      "to": {
        name: 'detail-legal-contacts',
        params: {
          companyID: _vm.legalPerson.id
        }
      }
    }
  }, [_c('span', {
    staticClass: "svg-icon menu-icon"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/Code/Plus.svg"
    }
  })], 1), _vm._v(" " + _vm._s(_vm.$t("Add email")) + " ")])], 1), _c('div', {
    staticClass: "d-flex align-items-center justify-content-between mb-2"
  }, [_vm._v(" " + _vm._s(_vm.$t("Phone")) + ": "), _vm.mainPhoneNumber ? _c('span', {
    staticClass: "text-muted",
    style: {
      minWidth: '0'
    }
  }, [_vm._v(_vm._s(_vm.mainPhoneNumber))]) : _c('router-link', {
    staticClass: "btn btn-xs btn-light-primary font-weight-bolder",
    attrs: {
      "to": {
        name: 'detail-legal-contacts',
        params: {
          companyID: _vm.legalPerson.id
        }
      }
    }
  }, [_c('span', {
    staticClass: "svg-icon menu-icon"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/Code/Plus.svg"
    }
  })], 1), _vm._v(" " + _vm._s(_vm.$t("Add phone")) + " ")])], 1), _vm.secondmentRelations ? _c('div', {
    staticClass: "d-flex align-items-center justify-content-between mb-2"
  }, [_vm._v(" " + _vm._s(_vm.$t("Client on")) + ": "), _c('span', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s(_vm.secondmentRelations) + " " + _vm._s(_vm.$t("assignments")))])]) : _vm._e(), _vm.contractorRelations ? _c('div', {
    staticClass: "d-flex align-items-center justify-content-between mb-2"
  }, [_vm._v(" " + _vm._s(_vm.$t("Contractor on")) + ": "), _c('span', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s(_vm.contractorRelations) + " " + _vm._s(_vm.$t("assignments")))])]) : _vm._e(), _vm.partnerRelations ? _c('div', {
    staticClass: "d-flex align-items-center justify-content-between mb-2"
  }, [_vm._v(" " + _vm._s(_vm.$t("Supplier on")) + ": "), _c('span', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s(_vm.partnerRelations) + " " + _vm._s(_vm.$t("assignments")))])]) : _vm._e(), !_vm.isMyCompany ? _c('div', {
    staticClass: "d-flex flex-wrap gap-1 mt-4"
  }, [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "title": _vm.secondmentRelations != 0 ? _vm.$t('Action disabled, used on Assignment') : ''
    }
  }, [_c('client-button', {
    attrs: {
      "loading": _vm.isSettingAsClient,
      "active": _vm.legalPerson.is_client_on_secondments,
      "disabled": _vm.secondmentRelations != 0
    },
    on: {
      "click": function click($event) {
        return _vm.setAsClientFunction(!_vm.legalPerson.is_client_on_secondments);
      }
    }
  })], 1), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "title": _vm.contractorRelations != 0 ? _vm.$t('Action disabled, used on Assignment') : ''
    }
  }, [_c('contractor-button', {
    attrs: {
      "loading": _vm.isSettingAsContractor,
      "active": _vm.legalPerson.is_contractor_on_secondments,
      "disabled": _vm.contractorRelations != 0
    },
    on: {
      "click": function click($event) {
        return _vm.setAsContractorFunction(!_vm.legalPerson.is_contractor_on_secondments);
      }
    }
  })], 1), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "title": _vm.partnerRelations != 0 ? _vm.$t('Action disabled, used on Assignment') : ''
    }
  }, [_c('supplier-button', {
    attrs: {
      "loading": _vm.isSettingAsPartner,
      "active": _vm.legalPerson.is_partner_on_secondments,
      "disabled": _vm.partnerRelations != 0
    },
    on: {
      "click": function click($event) {
        return _vm.setAsPartnerFunction(!_vm.legalPerson.is_partner_on_secondments);
      }
    }
  })], 1)]) : _vm._e()]), _c('div', {
    staticClass: "navi navi-bold navi-hover navi-active navi-link-rounded",
    attrs: {
      "role": "tablist"
    }
  }, [_vm._l(_vm.componentList, function (comp, idx) {
    return [comp.show ? _c('div', {
      key: idx,
      staticClass: "navi-item mb-2"
    }, [_c('router-link', {
      staticClass: "navi-link py-4",
      attrs: {
        "active-class": "active",
        "data-tab": idx,
        "data-toggle": "tab",
        "role": "tab",
        "aria-selected": "false",
        "to": {
          name: comp.routeName
        }
      }
    }, [_c('span', {
      staticClass: "navi-icon"
    }, [_c('i', {
      class: comp.icon
    })]), _c('span', {
      staticClass: "navi-text font-size-lg"
    }, [_vm._v(_vm._s(comp.name))])])], 1) : _vm._e()];
  })], 2)])])]), _c('div', {
    staticClass: "flex-row-fluid ml-lg-8"
  }, [_c('router-view', _vm._g(_vm._b({
    key: _vm.$route.fullPath
  }, 'router-view', _vm.currentDetailComponentProps, false), _vm.currentDetailComponentEvents))], 1)]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }